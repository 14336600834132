import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Header from "./header"
import "./layout.css"

const useStyles = makeStyles(theme => ({
  body: {
    margin: `0 auto`,
    maxWidth: "100%",
    backgroundColor: "#202122",
  },
  footer: {
    padding: 30,
    maxWidth: "100%",
    backgroundColor: "#FFFFFF",
    color: "#303030",
    textAlign: "center",
  },
}))

const Layout = ({ children }) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className={classes.body}>
        <main>{children}</main>
        <footer className={classes.footer}>
          © {new Date().getFullYear()}, {data.site.siteMetadata.title}
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
