import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MenuIcon from "@material-ui/icons/Menu"
import Box from "@material-ui/core/Box"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Link } from "@material-ui/core"
import Logo from "../images/transporter011.png"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
    color: "#303030",
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingTop: 12,
      paddingLeft: 10,
      paddingRight: 10,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 150,
      paddingRight: 150,
    },
  },
}))

export default function ButtonAppBar() {
  const matches = useMediaQuery("(min-width:600px)")
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (matches) {
    return (
      <AppBar className={classes.root} position="static">
        <Box display="flex">
          <Box flexGrow={1}>
            <Link href="/" color="inherit">
              <img
                src={Logo}
                alt="transporter011"
                width="190px"
                style={{ marginBottom: 0 }}
              />
            </Link>

            {/* <Typography variant="h6" className={classes.title}>
              Transporter 011
            </Typography> */}
          </Box>
          <Box>
            <Button color="inherit" href="/">
              Početna
            </Button>
          </Box>
          <Box>
            <Button color="inherit" href="/services">
              Usluge
            </Button>
          </Box>
          <Box>
            <Button color="inherit" href="/contact">
              Kontakt
            </Button>
          </Box>
        </Box>
      </AppBar>
    )
  } else {
    return (
      <AppBar className={classes.root} position="static">
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h6" className={classes.title}>
              Transporter 011
            </Typography>
          </Box>
          <IconButton
            color="inherit"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <Link href="/" color="inherit">
                Početna
              </Link>
            </MenuItem>
            <MenuItem>
              <Link href="/services" color="inherit">
                Usluge
              </Link>
            </MenuItem>
            <MenuItem>
              <Link href="/contact" color="inherit">
                Kontakt
              </Link>
            </MenuItem>
          </Menu>
        </Box>
      </AppBar>
    )
  }
}
